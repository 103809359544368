export const configColums = () => {
  return [
    {
      title: '序号',
      width: 60,
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '变量名',
      width: 150,
      dataIndex: 'enName',
      align: 'center'
    },
    {
      title: '变量别名',
      width: 150,
      dataIndex: 'enNameAlias',
      align: 'center'
    },
    {
      title: '描述',
      width: 150,
      dataIndex: 'cnName',
      align: 'center'
    },
    {
      title: '描述别名',
      width: 150,
      dataIndex: 'cnNameAlias',
      align: 'center'
    },
    {
      title: '类型',
      width: 80,
      dataIndex: 'metricsType',
      scopedSlots: { customRender: 'metricsType' },
      align: 'center'
    },
    {
      title: '标签',
      width: 150,
      dataIndex: 'tag',
      align: 'center'
    },
    {
      title: '网关名称',
      dataIndex: 'gateway',
      width: 150,
      scopedSlots: { customRender: 'gateway' },
      align: 'center'
    },
    {
      title: '地图是否显示',
      dataIndex: 'core',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'core' }
    },
    {
      title: '操作',
      width: 200,
      key: 'action',
      fixed: 'right',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]
}
export const metricsType = () => {
  return [
    { value: 'yc', name: '遥测' },
    { value: 'yx', name: '遥信' },
    { value: 'yt', name: '遥调' },
    { value: 'yk', name: '遥控' }
  ]
}
