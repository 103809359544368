<template>
  <div>
    <div class="fs-search-box">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="变量名">
            <a-input style="width:200px" allow-clear v-model="searchParams.enName" placeholder="输入变量名"></a-input>
          </a-form-item>
          <a-form-item label="描述">
            <a-input style="width:200px" allow-clear v-model="searchParams.cnName" placeholder="输入描述"></a-input>
          </a-form-item>
          <a-form-item label="标签">
            <a-input style="width:200px" allow-clear v-model="searchParams.tag" placeholder="输入描述"></a-input>
          </a-form-item>
          <a-form-item label="是否核心" >
            <a-select :default-value="coreList[0].value" style="width: 80px" @change="handleChange">
              <a-select-option v-for="item in coreList" :value="item.value" :key="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button class="m-l-6" type="primary" @click="search" icon="search">查询</a-button>
            <a-button style="margin-left: 16px;" type="primary" @click="handleAdd()">新增
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table
      bordered
      @change="tableChange"
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      :scroll="{ x: 1000 }"
      :loading="tableLoading"
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <template slot="metricsType" slot-scope="metricsType">
        <a-tag v-if="metricsType === 'yc'" color="#f50">遥测</a-tag>
        <a-tag v-if="metricsType === 'yx'" color="#2db7f5">遥信</a-tag>
        <a-tag v-if="metricsType === 'yt'" color="#87d068">遥调</a-tag>
        <a-tag v-if="metricsType === 'yk'" color="#108ee9">遥控</a-tag>
      </template>
      <template slot="core" slot-scope="core">
        {{ core ? '是' : '否' }}
      </template>
      <template slot="unit" slot-scope="record">
        {{ record ? record : '-' }}
      </template>
      <template slot="maxValue" slot-scope="record">
        {{ record ? record : '-' }}
      </template>
      <template slot="minValue" slot-scope="record">
        {{ record !== null ? record : '-' }}
      </template>
      <template slot="gateway" slot-scope="record">
        <a @click="clickGateway(record.id)">{{record.cnName}}</a>
      </template>
      <template slot="escapeContent" slot-scope="escapeContent">
          <span v-if="escapeContent">
          <a-tag v-if="JSON.parse(escapeContent).zero"> 0: {{JSON.parse(escapeContent).zero}}</a-tag>
            <span v-else>-</span>
          <a-tag v-if="JSON.parse(escapeContent).one">1:{{JSON.parse(escapeContent).one }}</a-tag>
             <span v-else>-</span>
            </span>
        <span v-else>--</span>
      </template>
      <span slot="action" slot-scope="text, record,index">
        <a-button
          shape="circle"
          icon="up"
          :disabled="index === 0"
          @click="handleMetricsOrderChange(record, index, 'up')"
          size="small"
        />
        <a-divider type="vertical"/>
        <a-button
          shape="circle"
          icon="down"
          :disabled="index === tableData.length - 1"
          @click="handleMetricsOrderChange(record, index, 'down')"
          size="small"
        />
          <a-divider type="vertical"/>
          <a @click="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)">删除</a>
        </span>
    </a-table>
    <a-modal v-model="addModal" :destroyOnClose="true" title="指标新增">
      <Form ref="addRef" type="add" :pointId="instanceId" :defaultType="searchParams.typeId" @addSuccess="addSuccess"
            @addError="addError"></Form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal :visible="editModal" :destroyOnClose="true" @ok="handleEditOk" @cancel="closeEditModal" title="指标编辑">
      <Form
        :pointId="instanceId"
        ref="editRef"
        type="edit"
        @editSuccess="editSuccess"
        @editError="editError"
      ></Form>
    </a-modal>
    <a-drawer
      title="网关详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="450px"
      :visible="detailDrawer"
      :mask="false"
    >
      <gateway-detail ref="viewRef"></gateway-detail>
    </a-drawer>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import Form from './Form'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import { configColums } from './common/common'
  import gatewayDetail from '../../gateway/Detail'

  export default {
    components: { Form, gatewayDetail },
    mixins: [entityCRUDWithCopy],
    name: 'localImageForm',
    props: {
      instanceId: {
        type: [Number, String],
        required: true
      }
    },
    data() {
      return {
        sup_this: this,
        entityBaseUrl: SERVICE_URLS.equipment2.monitoringRealInfo,
        tableData: [],
        initColumns: configColums(),
        searchParams: {
          direction: 'ASC',
          order: 'sorter',
          deviceId: this.instanceId,
          cnName: '',
          tag: '',
          typeId: undefined,
          core: '-1',
          enName: ''
        },
        detailDrawer: false,
        coreList: [
          {
            value: '-1',
            name: '全部'
          },
          {
            value: '1',
            name: '是'
          },
          {
            value: '0',
            name: '否'
          }
        ]
      }
    },
    created() {
    },
    watch: {
      'instanceId': function (val) {
        this.searchParams.deviceId = val
        this.search()
      },
      'searchParams.typeId': function (val) {
        this.search()
      }
    },
    methods: {
      addSuccess(addType) {
        if (addType === 'copy') {
          // 表示不关闭Modal
          this.$refs.addRef.formItem.gatewayId = this.$refs.addRef.gatewayId
          this.addLoading = false
        } else if (addType === 'continue') {
          this.$refs.addRef.gatewayName = ''
          this.addLoading = false
        } else {
          // 直接关闭Modal
          this.$refs.addRef.gatewayName = ''
          this.addLoading = false
          this.addModal = false
        }
        this.addLoading = false
        this.search()
      },
      handleMetricsOrderChange(record, index, type) {
        const sortList = []
        if (type === 'up') {
          const selectSortData = {
            id: record.id,
            sorter: this.tableData[index - 1].sorter
          }
          sortList.push(selectSortData)
          const upRowSortData = {
            id: this.tableData[index - 1].id,
            sorter: record.sorter
          }
          sortList.push(upRowSortData)
        } else {
          const selectSortData = {
            id: record.id,
            sorter: this.tableData[index + 1].sorter
          }
          sortList.push(selectSortData)
          const upRowSortData = {
            id: this.tableData[index + 1].id,
            sorter: record.sorter
          }
          sortList.push(upRowSortData)
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringRealInfo.update_sorter,
          data: { idSorterMappings: sortList },
          noTips: true,
          success: () => {
            this.search()
          }
        })
      },
      clickGateway(id) {
        this.detailDrawer = true
        this.$nextTick(() => {
          this.$refs.viewRef.loadData(id)
        })
      },
      handleChange(value) {
        this.searchParams.core = value
      }
    }
  }
</script>

<style scoped></style>
